exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-aktuelles-jsx": () => import("./../../../src/pages/aktuelles.jsx" /* webpackChunkName: "component---src-pages-aktuelles-jsx" */),
  "component---src-pages-galerie-jsx": () => import("./../../../src/pages/galerie.jsx" /* webpackChunkName: "component---src-pages-galerie-jsx" */),
  "component---src-pages-hunde-index-jsx": () => import("./../../../src/pages/hunde/index.jsx" /* webpackChunkName: "component---src-pages-hunde-index-jsx" */),
  "component---src-pages-hunde-strapi-hund-name-jsx": () => import("./../../../src/pages/hunde/{strapiHund.name}.jsx" /* webpackChunkName: "component---src-pages-hunde-strapi-hund-name-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-ueberuns-jsx": () => import("./../../../src/pages/ueberuns.jsx" /* webpackChunkName: "component---src-pages-ueberuns-jsx" */),
  "component---src-pages-wuerfe-strapi-wurfs-name-jsx": () => import("./../../../src/pages/wuerfe/{strapiWurfs.name}.jsx" /* webpackChunkName: "component---src-pages-wuerfe-strapi-wurfs-name-jsx" */),
  "component---src-pages-wurfplanung-jsx": () => import("./../../../src/pages/wurfplanung.jsx" /* webpackChunkName: "component---src-pages-wurfplanung-jsx" */),
  "component---src-pages-zucht-strapi-zucht-name-jsx": () => import("./../../../src/pages/zucht/{strapiZucht.name}.jsx" /* webpackChunkName: "component---src-pages-zucht-strapi-zucht-name-jsx" */)
}

